.content-container {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    //display: flex;
    position: relative;
}

.video-container {
    position: relative;
    width: 420px;
    height: 340px;
    display: flex;
    border: 5px solid black;
}

.video-content {
    width: 100%;
    height: 100%;
}