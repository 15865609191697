div.timeline-frame {
    -moz-box-sizing: border-box;
    border: 1px solid #bebebe;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

div.timeline-content {
    overflow: hidden;
    position: relative;
}

div.timeline-content>div:first-child {
  background-color: #f8f8f8;
  height: 8px !important;
  margin-top: 8px;
}

div.timeline-axis {
    -moz-box-sizing: border-box;
    border-color: #bebebe;
    border-top-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

div.timeline-axis-grid {
    -moz-box-sizing: border-box;
    border-left-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

div.timeline-axis-grid-minor {
    border-color: #e5e5e5;
}

div.timeline-axis-grid-major {
    border-color: #bfbfbf;
}

div.timeline-axis-text {
    color: #4d4d4d;
    padding: 3px;
    white-space: nowrap;
}

div.timeline-axis-text-minor {
  top: 10px !important;
}

/* div.timeline-axis-text-major {
} */

.Filtered {
  opacity: 0.05;
  z-index: 0 !important;
}

div.timeline-event {
    -moz-box-sizing: border-box;
    /* background-color: #8dc63f; */
    border-color: #8dc63f;
    box-sizing: border-box;
    color: #1a1a1a;
    display: inline-block;
}

/* div.timeline-event-selected {
    background-color: #fff785;
    border-color: #ffc200;
    z-index: 999;
} */

/* TODO: use another color or pattern? */
div.timeline-event-cluster,
div.timeline-event-cluster .popup-line {
    background: url('img/cluster_bg.png') #8dc63f;
    color: #ffffff;
}

div.timeline-event-cluster div.timeline-event-dot {
    border-color: #d5ddf6;
}

div.timeline-event-cluster div.MotionDetection,
div.timeline-event-cluster div.MotionDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
}

div.timeline-event-cluster div.Normal,
div.timeline-event-cluster div.Normal .popup-line {
  background: #8dc63f !important;
  color: #ffffff;
  z-index: 1;
}

div.timeline-event-cluster div.Manual,
div.timeline-event-cluster div.Manual .popup-line {
  background: #8dc63f !important;
  color: #ffffff;
  z-index: 2;
}

div.timeline-event-cluster div.MotionDetection,
div.timeline-event-cluster div.MotionDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.VideoAnalysis,
div.timeline-event-cluster div.VideoAnalysis .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.FaceDetection,
div.timeline-event-cluster div.FaceDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.TamperingDetection,
div.timeline-event-cluster div.TamperingDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AutoTracking,
div.timeline-event-cluster div.AutoTracking .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.DefocusDetection,
div.timeline-event-cluster div.DefocusDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.FogDetection,
div.timeline-event-cluster div.FogDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AudioDetection,
div.timeline-event-cluster div.AudioDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AudioAnalysis,
div.timeline-event-cluster div.AudioAnalysis .popup-line {
  background:  #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AlarmInput,
div.timeline-event-cluster div.AlarmInput .popup-line {
  background:  #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.UserInput,
div.timeline-event-cluster div.UserInput .popup-line {
  background:  #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-box {
    -moz-border-radius: 5px; /* For Firefox 3.6 and older */
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    text-align: center;
}

div.timeline-event-dot {
    -moz-border-radius: 5px; /* For Firefox 3.6 and older */
    border-radius: 5px;
    border-style: solid;
    border-width: 5px;
}

div.timeline-event-range {
    -moz-border-radius: 2px; /* For Firefox 3.6 and older */
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
}

div.timeline-event-range-drag-left {
    cursor: w-resize;
    z-index: 1000;
}

div.timeline-event-range-drag-right {
    cursor: e-resize;
    z-index: 1000;
}

div.timeline-event-line {
    -moz-box-sizing: border-box;
    border-left-style: solid;
    border-left-width: 1px;
    box-sizing: border-box;
}

div.timeline-event-content {
    margin: 5px;
    overflow: hidden;
    white-space: nowrap;
}

div.timeline-groups-axis {
    -moz-box-sizing: border-box;
    border-color: #bebebe;
    border-width: 1px;
    box-sizing: border-box;
}

div.timeline-groups-axis-onleft {
    border-style: none solid none none;
}

div.timeline-groups-axis-onright {
    border-style: none none none solid;
}

div.timeline-groups-text {
    color: #4d4d4d;
    padding-left: 10px;
    padding-right: 10px;
}

div.timeline-currenttime {
    -moz-box-sizing: border-box;
    background-color: #ff7f6e;
    box-sizing: border-box;
    width: 2px;
}

div.timeline-customtime {
    -moz-box-sizing: border-box;
    background-color: #6e94ff;
    box-sizing: border-box;
    cursor: move;
    width: 2px;
    z-index: 100;
}

div.timeline-navigation {
    -moz-border-radius: 2px; /* For Firefox 3.6 and older */
    -moz-box-sizing: border-box;
    background-color: #f5f5f5;
    border: 1px solid #bebebe;
    border-radius: 2px;
    box-sizing: border-box;
    color: #808080;
    font-family: arial;
    font-size: 20px;
    font-weight: bold;
}

div.timeline-navigation-new,
div.timeline-navigation-delete,
div.timeline-navigation-zoom-in,
div.timeline-navigation-zoom-out,
div.timeline-navigation-move-left,
div.timeline-navigation-move-right {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    float: left;
    height: 36px;
    padding: 10px;
    text-decoration: none;
    width: 36px;
}

div.timeline-navigation-new {
    background: url('img/16/new.png') no-repeat center;
}

/* separator between new and navigation buttons */
div.timeline-navigation-new-line {
    border-right: 1px solid #bebebe;
}

div.timeline-navigation-delete {
    background: url('img/16/delete.png') no-repeat center;
}

div.timeline-navigation-zoom-in {
    background: url('img/16/zoomin.png') no-repeat center;
}

div.timeline-navigation-zoom-out {
    background: url('img/16/zoomout.png') no-repeat center;
}

div.timeline-navigation-move-left {
    background: url('img/16/moveleft.png') no-repeat center;
}

div.timeline-navigation-move-right {
    background: url('img/16/moveright.png') no-repeat center;
}


/* Custom CSS */

div.timeline-frame,
div.timeline-axis-grid-minor,
div.timeline-groups-axis,
div.timeline-axis {
    border: none;
}

div.timeline-axis-text {
    font-size: 12px;
    color: #797979;
}

div.timeline-customtime {
    width: 18px;
    height: 18px !important;
    border-radius: 100%;
    background-color: white;
    border: 5px solid #333333;
    transform: translate(-9px, 3px);
}

div.timeline-event {
  top: 9px !important;
  height: 6px;
  border: none;
}

div.timeline-event-content {
    display: none;
}

div.Normal,
div.Normal .popup-line {
    background-color: #8dc63f;
    color: #ffffff;
    z-index: 1;
}

div.Manual,
div.Manual .popup-line {
  background-color: #8dc63f;
  color: #ffffff;
  z-index: 2;
}

div.MotionDetection,
div.MotionDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.VideoAnalysis,
div.VideoAnalysis .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.FaceDetection,
div.FaceDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.TamperingDetection,
div.TamperingDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AutoTracking,
div.AutoTracking .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.DefocusDetection,
div.DefocusDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.FogDetection,
div.FogDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AudioDetection,
div.AudioDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AudioAnalysis,
div.AudioAnalysis .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AlarmInput,
div.AlarmInput .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.UserInput,
div.UserInput .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}