/* Generated by grunt-webfont */



@font-face {
	font-family:"icons";
	src:url(/uwa/static/media/Wisenet-Icon.ed9396c7.woff2) format("woff2"),
		url(/uwa/static/media/Wisenet-Icon.e3e9f431.woff) format("woff"),
		url(/uwa/static/media/Wisenet-Icon.0d7df9cb.ttf) format("truetype"),
		url(/uwa/static/media/Wisenet-Icon.bc8abdbc.svg#icons) format("svg");
	font-weight:normal;
	font-style:normal;
}

.wni {
	
		font-family:"icons";
	
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


/* Icons */


.wni-add:before {
	content:"\F101";
}


.wni-alarm-device:before {
	content:"\F102";
}


.wni-alarm-hdd:before {
	content:"\F103";
}


.wni-alarm-network:before {
	content:"\F104";
}


.wni-alarm-notice:before {
	content:"\F105";
}


.wni-alarm-off:before {
	content:"\F106";
}


.wni-alarm-on:before {
	content:"\F107";
}


.wni-alarm:before {
	content:"\F108";
}


.wni-areazoom:before {
	content:"\F109";
}


.wni-arrow-down:before {
	content:"\F10A";
}


.wni-arrow-left:before {
	content:"\F10B";
}


.wni-arrow-right:before {
	content:"\F10C";
}


.wni-arrow-up:before {
	content:"\F10D";
}


.wni-aspact-ratio:before {
	content:"\F10E";
}


.wni-back-jump:before {
	content:"\F10F";
}


.wni-back-slow:before {
	content:"\F110";
}


.wni-backmode:before {
	content:"\F111";
}


.wni-backward-slow:before {
	content:"\F112";
}


.wni-camera-analog-authority:before {
	content:"\F113";
}


.wni-camera-analog-covert:before {
	content:"\F114";
}


.wni-camera-analog-videoloss:before {
	content:"\F115";
}


.wni-camera-analog:before {
	content:"\F116";
}


.wni-camera-box-authority:before {
	content:"\F117";
}


.wni-camera-box-covert:before {
	content:"\F118";
}


.wni-camera-box-rec:before {
	content:"\F119";
}


.wni-camera-box-videoloss:before {
	content:"\F11A";
}


.wni-camera-box:before {
	content:"\F11B";
}


.wni-camera-ptz-authority:before {
	content:"\F11C";
}


.wni-camera-ptz-covert:before {
	content:"\F11D";
}


.wni-camera-ptz-rec:before {
	content:"\F11E";
}


.wni-camera-ptz-videoloss:before {
	content:"\F11F";
}


.wni-camera-ptz:before {
	content:"\F120";
}


.wni-camerainfo-showhide:before {
	content:"\F121";
}


.wni-capture:before {
	content:"\F122";
}


.wni-caution:before {
	content:"\F123";
}


.wni-close:before {
	content:"\F124";
}


.wni-date:before {
	content:"\F125";
}


.wni-delete:before {
	content:"\F126";
}


.wni-dzoom:before {
	content:"\F127";
}


.wni-edit:before {
	content:"\F128";
}


.wni-event-defoucs:before {
	content:"\F129";
}


.wni-event-face:before {
	content:"\F12A";
}


.wni-event-iframe-video:before {
	content:"\F12B";
}


.wni-event-iva:before {
	content:"\F12C";
}


.wni-event-motion:before {
	content:"\F12D";
}


.wni-event-sdcard-full:before {
	content:"\F12E";
}


.wni-event-sdcard-over:before {
	content:"\F12F";
}


.wni-event-sensor:before {
	content:"\F130";
}


.wni-event-tampering:before {
	content:"\F131";
}


.wni-event-textevent:before {
	content:"\F132";
}


.wni-export:before {
	content:"\F133";
}


.wni-filter:before {
	content:"\F134";
}


.wni-fisheyemode:before {
	content:"\F135";
}


.wni-forward-slow:before {
	content:"\F136";
}


.wni-front-jump:before {
	content:"\F137";
}


.wni-front-slow:before {
	content:"\F138";
}


.wni-full-screen:before {
	content:"\F139";
}


.wni-help:before {
	content:"\F13A";
}


.wni-instant-playback:before {
	content:"\F13B";
}


.wni-list-view:before {
	content:"\F13C";
}


.wni-logout:before {
	content:"\F13D";
}


.wni-mic:before {
	content:"\F13E";
}


.wni-monitoring:before {
	content:"\F13F";
}


.wni-osd-showhide:before {
	content:"\F140";
}


.wni-page-sequence:before {
	content:"\F141";
}


.wni-pattern-1:before {
	content:"\F142";
}


.wni-pattern-4:before {
	content:"\F143";
}


.wni-pattern-9:before {
	content:"\F144";
}


.wni-pause:before {
	content:"\F145";
}


.wni-pc-recording:before {
	content:"\F146";
}


.wni-play:before {
	content:"\F147";
}


.wni-print:before {
	content:"\F148";
}


.wni-ptz-control:before {
	content:"\F149";
}


.wni-ptz-far:before {
	content:"\F14A";
}


.wni-ptz-group:before {
	content:"\F14B";
}


.wni-ptz-mode-arrow:before {
	content:"\F14C";
}


.wni-ptz-near:before {
	content:"\F14D";
}


.wni-ptz-preset:before {
	content:"\F14E";
}


.wni-ptz-swing:before {
	content:"\F14F";
}


.wni-ptz-tour:before {
	content:"\F150";
}


.wni-ptz-trace:before {
	content:"\F151";
}


.wni-ptz-zoomin:before {
	content:"\F152";
}


.wni-ptz-zoomout:before {
	content:"\F153";
}


.wni-rec-status:before {
	content:"\F154";
}


.wni-recording:before {
	content:"\F155";
}


.wni-refresh:before {
	content:"\F156";
}


.wni-return-1-x:before {
	content:"\F157";
}


.wni-rotate:before {
	content:"\F158";
}


.wni-save:before {
	content:"\F159";
}


.wni-search:before {
	content:"\F15A";
}


.wni-setup:before {
	content:"\F15B";
}


.wni-side-showhidden:before {
	content:"\F15C";
}


.wni-sorting:before {
	content:"\F15D";
}


.wni-sound:before {
	content:"\F15E";
}


.wni-status:before {
	content:"\F15F";
}


.wni-textsearch:before {
	content:"\F160";
}


.wni-thumbnail-view:before {
	content:"\F161";
}


.wni-tile-logo:before {
	content:"\F162";
}


.wni-timelinepicker:before {
	content:"\F163";
}


.wni-timesearch:before {
	content:"\F164";
}


.wni-tracking-auto:before {
	content:"\F165";
}


.wni-tracking-manual:before {
	content:"\F166";
}


.wni-user:before {
	content:"\F167";
}


.wni-video-authority:before {
	content:"\F168";
}


.wni-video-disconnect:before {
	content:"\F169";
}


.wni-video-maxuser:before {
	content:"\F16A";
}


.wni-video-power:before {
	content:"\F16B";
}


.wni-video-videoloss:before {
	content:"\F16C";
}

@font-face {
  font-family: 'NotoSans';
  src: url(/uwa/static/media/NotoSans-Regular.2fd9c16b.ttf) format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/uwa/static/media/NotoSans-Italic.154da469.ttf) format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/uwa/static/media/NotoSans-Bold.a165a426.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/uwa/static/media/NotoSans-BoldItalic.4f6ff363.ttf) format('truetype');
  font-style: italic;
  font-weight: 700;
}

.live-playback-template {
  width: 100%;
  height: calc(100vh);
  display: -webkit-flex;
  display: flex; }
  .live-playback-template .sidebar {
    -webkit-flex-basis: 300px;
            flex-basis: 300px;
    border-right: 1px solid #ccc; }
    .live-playback-template .sidebar li {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #ccc; }
  .live-playback-template .content {
    -webkit-flex-grow: 1;
            flex-grow: 1; }


input[type=radio] {
  margin-right: 8px;
  margin-top: 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: black;
  border: 2px solid black;
  cursor: pointer; }

.radio.label {
  margin-left: 8px; }

.form-label.capitalize {
  text-transform: capitalize; }

form {
  border: 3px solid #ccc;
  padding: 0 20px 25px 20px;
  border-radius: 10px; }

input[type=checkbox],
input[type=radio] {
  margin-right: 8px; }

.form-label.capitalize {
  text-transform: capitalize; }

.checkbox-group {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.checkbox-group .form-label {
  width: 50%; }

input[type=submit],
.btn-link {
  margin-top: 20px; }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.disable-short-mode [class*='fc-content'] [class*='fc-time']:after, .disable-short-mode [class*='fc-content'] [class*='fc-time']:before {
  display: none !important; }

.disable-short-mode [class*='fc-content'] [class*='fc-time'] span {
  display: unset !important; }

[class*='fc-toolbar'] {
  display: none !important; }

[class*='fc-axis'] {
  width: 31px !important; }

[class*='fc-day-header'] {
  font-weight: bold; }

.weekly-scheduler {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .weekly-scheduler .toolbar {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 10px; }
    .weekly-scheduler .toolbar .toolbar-button {
      margin-right: 10px;
      padding: 5px 10px; }

div.timeline-frame {
    -moz-box-sizing: border-box;
    border: 1px solid #bebebe;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

div.timeline-content {
    overflow: hidden;
    position: relative;
}

div.timeline-content>div:first-child {
  background-color: #f8f8f8;
  height: 8px !important;
  margin-top: 8px;
}

div.timeline-axis {
    -moz-box-sizing: border-box;
    border-color: #bebebe;
    border-top-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

div.timeline-axis-grid {
    -moz-box-sizing: border-box;
    border-left-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

div.timeline-axis-grid-minor {
    border-color: #e5e5e5;
}

div.timeline-axis-grid-major {
    border-color: #bfbfbf;
}

div.timeline-axis-text {
    color: #4d4d4d;
    padding: 3px;
    white-space: nowrap;
}

div.timeline-axis-text-minor {
  top: 10px !important;
}

/* div.timeline-axis-text-major {
} */

.Filtered {
  opacity: 0.05;
  z-index: 0 !important;
}

div.timeline-event {
    -moz-box-sizing: border-box;
    /* background-color: #8dc63f; */
    border-color: #8dc63f;
    box-sizing: border-box;
    color: #1a1a1a;
    display: inline-block;
}

/* div.timeline-event-selected {
    background-color: #fff785;
    border-color: #ffc200;
    z-index: 999;
} */

/* TODO: use another color or pattern? */
div.timeline-event-cluster,
div.timeline-event-cluster .popup-line {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAAZiS0dEAAAAAAAA+UO7fwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAd0SU1FB9wJHA0XFd5xD4wAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAALElEQVQI103HsQ2AMBAAMQexTOrffwRmuhQICXdWqXY1lRvj9cD1D2ZVvsABUlgRiADWVf8AAAAASUVORK5CYII=) #8dc63f;
    color: #ffffff;
}

div.timeline-event-cluster div.timeline-event-dot {
    border-color: #d5ddf6;
}

div.timeline-event-cluster div.MotionDetection,
div.timeline-event-cluster div.MotionDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
}

div.timeline-event-cluster div.Normal,
div.timeline-event-cluster div.Normal .popup-line {
  background: #8dc63f !important;
  color: #ffffff;
  z-index: 1;
}

div.timeline-event-cluster div.Manual,
div.timeline-event-cluster div.Manual .popup-line {
  background: #8dc63f !important;
  color: #ffffff;
  z-index: 2;
}

div.timeline-event-cluster div.MotionDetection,
div.timeline-event-cluster div.MotionDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.VideoAnalysis,
div.timeline-event-cluster div.VideoAnalysis .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.FaceDetection,
div.timeline-event-cluster div.FaceDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.TamperingDetection,
div.timeline-event-cluster div.TamperingDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AutoTracking,
div.timeline-event-cluster div.AutoTracking .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.DefocusDetection,
div.timeline-event-cluster div.DefocusDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.FogDetection,
div.timeline-event-cluster div.FogDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AudioDetection,
div.timeline-event-cluster div.AudioDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AudioAnalysis,
div.timeline-event-cluster div.AudioAnalysis .popup-line {
  background:  #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.AlarmInput,
div.timeline-event-cluster div.AlarmInput .popup-line {
  background:  #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-cluster div.UserInput,
div.timeline-event-cluster div.UserInput .popup-line {
  background:  #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.timeline-event-box { /* For Firefox 3.6 and older */
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    text-align: center;
}

div.timeline-event-dot { /* For Firefox 3.6 and older */
    border-radius: 5px;
    border-style: solid;
    border-width: 5px;
}

div.timeline-event-range { /* For Firefox 3.6 and older */
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
}

div.timeline-event-range-drag-left {
    cursor: w-resize;
    z-index: 1000;
}

div.timeline-event-range-drag-right {
    cursor: e-resize;
    z-index: 1000;
}

div.timeline-event-line {
    -moz-box-sizing: border-box;
    border-left-style: solid;
    border-left-width: 1px;
    box-sizing: border-box;
}

div.timeline-event-content {
    margin: 5px;
    overflow: hidden;
    white-space: nowrap;
}

div.timeline-groups-axis {
    -moz-box-sizing: border-box;
    border-color: #bebebe;
    border-width: 1px;
    box-sizing: border-box;
}

div.timeline-groups-axis-onleft {
    border-style: none solid none none;
}

div.timeline-groups-axis-onright {
    border-style: none none none solid;
}

div.timeline-groups-text {
    color: #4d4d4d;
    padding-left: 10px;
    padding-right: 10px;
}

div.timeline-currenttime {
    -moz-box-sizing: border-box;
    background-color: #ff7f6e;
    box-sizing: border-box;
    width: 2px;
}

div.timeline-customtime {
    -moz-box-sizing: border-box;
    background-color: #6e94ff;
    box-sizing: border-box;
    cursor: move;
    width: 2px;
    z-index: 100;
}

div.timeline-navigation {
    -moz-border-radius: 2px; /* For Firefox 3.6 and older */
    -moz-box-sizing: border-box;
    background-color: #f5f5f5;
    border: 1px solid #bebebe;
    border-radius: 2px;
    box-sizing: border-box;
    color: #808080;
    font-family: arial;
    font-size: 20px;
    font-weight: bold;
}

div.timeline-navigation-new,
div.timeline-navigation-delete,
div.timeline-navigation-zoom-in,
div.timeline-navigation-zoom-out,
div.timeline-navigation-move-left,
div.timeline-navigation-move-right {
    box-sizing: border-box;
    cursor: pointer;
    float: left;
    height: 36px;
    padding: 10px;
    text-decoration: none;
    width: 36px;
}

div.timeline-navigation-new {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAl2cEFnAAAAEAAAABAAXMatwwAAAQxJREFUOMul0T1KQ1EQBeAv5tlJEpvwIu5Fd+AiBG3tBIPlayzE2p8NWAguwb34Ey30JRG7RIvMk2swvIgHhnvn3pnDmTkNNcgL0MEB1vGGM5SDPlkdQWANu9jEPS5RQpYXOlEwj2GcLfTQjLwZ+TQvfGQhbQ9TNKIoQ4FPHMXZjb8ubuN+nqEdjOOIFC3kc2+VAminO7jACVYjf8U+BomCJiZ4qcZMCcZ4HvR/bP8K19gI2b1o3sEDsx38iiAa5YVR7GYSXxM84fEvNo5wGm6ViUNW6jorJWbeDxOnvu1aFm0cxv0mdlavoA7/JkhH2MJxXiwkbUUMFxFsR9ShnCe4i1GmSzQ3guC9evgCBe4/1v+bq20AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDExLTA1LTE2VDExOjQzOjAwKzAyOjAwNpIs6wAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxMS0wNS0xNlQxMTo0MjoxNyswMjowMKEAwXkAAAAASUVORK5CYII=) no-repeat center;
}

/* separator between new and navigation buttons */
div.timeline-navigation-new-line {
    border-right: 1px solid #bebebe;
}

div.timeline-navigation-delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAl2cEFnAAAAEAAAABAAXMatwwAAAVRJREFUOMt108FKG1EUBuBvYtxbRGwxiGBXeYCW0mVpDSKVvIJ0F/syJsFdn6GCkaZ9gro3G1u0ILQgheJKRUwXPQNnxvTAcGf+c/7/nPvfO8UEKLCFJg5w31aNqGtgG3c4xLSZyP0QgI8Tpu0quQhyPwTgsBnkAVYD3MM9DibVId5GbiW+B+VIt7hJha1IdhPWDayVsBvcFtHlDYZ4mgou8D7e+zXyN/TwuUhjbkSXLHIZ61KNvItxaYyayBDrZsf36DyGdilQE+ngA57UyD+xg08luTSxHvP/wRuRewDm7uV9WJ4hsBy5rczJJm7G/tcS6VesjxN2Hj4c5S10Qj2Tz/AunrOEr0VtB+Z6vMJ+zfkfcVQjnMbRvcRC5B/hBU4auMbVjBFHCRsFdp6wK1yXHjz37xItRuejfFQ1nwb4HXVfs4nPYsQv0p+oKlLgNf7gGP4Cw4ZUK7kMJVEAAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDExLTA1LTE2VDExOjM1OjIxKzAyOjAw9uVF5gAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxMS0wNS0xNlQxMTozNToyMSswMjowMIe4/VoAAAAASUVORK5CYII=) no-repeat center;
}

div.timeline-navigation-zoom-in {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAJiS0dEAP+Hj8y/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAACXZwQWcAAAAQAAAAEABcxq3DAAAAeElEQVQoz83QzQnCQBQE4M9saggsbiOpwo5ySEepIo2sCulhg5d1UTyIF8mcHo/5Y04qJpJVQjbKc/13vuAPhH5K9dpFAQTRPlVpb23kYACDRWkO0odrEH/pIL9FBBTba8TYSp4tIjYX16d3P+c2dVd1xd3tSFM/APtKGKVy0oAyAAAAGXRFWHRDb21tZW50AENyZWF0ZWQgd2l0aCBHSU1QV4EOFwAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAxMS0wNS0xNlQxMDoyNjozMSswMjowMM1g+D4AAAAldEVYdGRhdGU6bW9kaWZ5ADIwMTEtMDUtMTZUMTA6MjY6MzErMDI6MDC8PUCCAAAAAElFTkSuQmCC) no-repeat center;
}

div.timeline-navigation-zoom-out {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAJiS0dEAP+Hj8y/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAACXZwQWcAAAAQAAAAEABcxq3DAAAAKElEQVQoz2NgGAKAsU4GvwIWhuOEFBAwgYmQG1gYnhBSYDkQYUNlAAAGtAKuwQMJjwAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTEtMDUtMTZUMTA6Mjc6MjMrMDI6MDB5l4K3AAAAJXRFWHRkYXRlOm1vZGlmeQAyMDExLTA1LTE2VDEwOjI3OjIzKzAyOjAwCMo6CwAAAABJRU5ErkJggg==) no-repeat center;
}

div.timeline-navigation-move-left {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAJiS0dEAP+Hj8y/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAACXZwQWcAAAAQAAAAEABcxq3DAAAA6ElEQVQoz3XPTStEARiG4evMOdRkkkxMGBtlYUmkfHSwUBaysVBm+Cez84sM+7OzUkpKVjQ+FsZGIYsZizlpmjnzLN/77ul9AgNSI2/DepCJGLGmYk8zyoCjdpzaMob3qAcW7araVEiPregfBkr2VS3Ld1W2oxSWHahYNNzzUDuqheYcOrJgKGNMK7LtzIqMNR0hjD88KSj3lXfyGsY/7tXdKpoW9QkvYSIR/7pT92BCqUd6DiGRiL/dOPdoyqRcj/Avfbl24c2MYio1wu6+RCL+dOVS06xxgUbmvBo5804cDxBSKbRk9Q8FcTWjp67rDQAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTEtMDUtMTZUMTA6Mjk6MTkrMDI6MDBNoeqpAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDExLTA1LTE2VDEwOjI5OjE5KzAyOjAwPPxSFQAAAABJRU5ErkJggg==) no-repeat center;
}

div.timeline-navigation-move-right {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAgY0hSTQAAeiYAAICEAAD6AAAAgOgAAHUwAADqYAAAOpgAABdwnLpRPAAAAAJiS0dEAP+Hj8y/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAACXZwQWcAAAAQAAAAEABcxq3DAAAA7ElEQVQoz3XRPUtCARTG8Z+k4B7U2FrQy9Z621oa+hp9hkDCTxEYhJXgEC0O0SIJGQQSRZBBEbgkIUQkYlF6G6S81vWs53+ec87zJDJbqs50s+IrkambdOzAuQ7ZGODWHF6dyit7+wtNBBumkDZrzbK+ZtANVCIKN+YjA101+0qehdlfhekIkDJj1Yq0ZtAOVCQyV5Zijv9UV3ToMakf+13KogXrNpPCMQZ0lO2oxQPvqradaPN/xYeanJKXgSOjCl+u5RxpDe0aAj13dhU9/TgQBUIP8goao80B0NNQsOdePy7RpKILl3rj4v4Gw9BST/3wWD8AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDExLTA1LTE2VDEwOjI5OjQ1KzAyOjAwwuGOuQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAxMS0wNS0xNlQxMDoyOTo0NSswMjowMLO8NgUAAAAASUVORK5CYII=) no-repeat center;
}


/* Custom CSS */

div.timeline-frame,
div.timeline-axis-grid-minor,
div.timeline-groups-axis,
div.timeline-axis {
    border: none;
}

div.timeline-axis-text {
    font-size: 12px;
    color: #797979;
}

div.timeline-customtime {
    width: 18px;
    height: 18px !important;
    border-radius: 100%;
    background-color: white;
    border: 5px solid #333333;
    -webkit-transform: translate(-9px, 3px);
            transform: translate(-9px, 3px);
}

div.timeline-event {
  top: 9px !important;
  height: 6px;
  border: none;
}

div.timeline-event-content {
    display: none;
}

div.Normal,
div.Normal .popup-line {
    background-color: #8dc63f;
    color: #ffffff;
    z-index: 1;
}

div.Manual,
div.Manual .popup-line {
  background-color: #8dc63f;
  color: #ffffff;
  z-index: 2;
}

div.MotionDetection,
div.MotionDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.VideoAnalysis,
div.VideoAnalysis .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.FaceDetection,
div.FaceDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.TamperingDetection,
div.TamperingDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AutoTracking,
div.AutoTracking .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.DefocusDetection,
div.DefocusDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.FogDetection,
div.FogDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AudioDetection,
div.AudioDetection .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AudioAnalysis,
div.AudioAnalysis .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.AlarmInput,
div.AlarmInput .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}

div.UserInput,
div.UserInput .popup-line {
  background: #ff0000 !important;
  color: #ffffff;
  z-index: 3;
}
.TileCommonBar {
  position: absolute;
  height: 40px;
  width: 100%;
  bottom: 0%;
  right: 0%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background: #000000; }

.TileCommonBar-icon {
  height: 40px;
  width: 40px;
  color: #ffffff;
  padding-right: 2rem;
  padding-left: 2rem; }
  .TileCommonBar-icon:hover {
    color: #ff9832 !important; }

/**
 * Extra class applied to VirtualScroll through className prop
 */
 .rst__virtualScrollOverride {
  overflow: auto !important;
  will-change: auto !important;
}
.rst__virtualScrollOverride * {
  box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__node.rst__rtl {
  text-align: right;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  /* height: 100%; */
  padding: 0 5px 0 10px;
  /* min-width: 170px; */
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

/* .rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
} */

/* .rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
} */

/* .rst__rowLabel {
  flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
} */

/* .rst__rowToolbar {
  flex: 0 1 auto;
  display: flex;
} */

/* .rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
} */

/* .rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
} */

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__rowTitle {
  font-size: 14px;
  /* font-weight: bold; */
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  /* box-shadow: 0 0 0 1px #000; */
  width: 12px;
  height: 12px;
  padding: 0;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  /* box-shadow: 0 0 0 1px #000, 0 0 1px 3px; */
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 16px;
  height: 14px;
  width: 14px;
}

.rst__collapseButton {
  background:
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4NCiAgICA8cGF0aCBmaWxsPSIjNEM0QzRDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4xMTUgMTQuODkzYS40Mi40MiAwIDAgMS0uMjk1LS4xMjJMMi4zOCA3LjMyOWwuNTg5LS41ODkgNy4xNDYgNy4xNDggNy4xNDktNy4xNDguNTg5LjU5LTcuNDQzIDcuNDQyYS40Mi40MiAwIDAgMS0uMjk1LjEyMiIvPg0KPC9zdmc+DQo=')
    no-repeat center;
}

.rst__expandButton {
  background:
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4NCiAgICA8cGF0aCBmaWxsPSIjNEM0QzRDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjUwNiAybDYuMzkgNS43NzJjLjEzOS4xMjYuMTM5LjMzIDAgLjQ1N0w1LjUwNSAxNCA1IDEzLjU0MyAxMS4xMzYgOCA1IDIuNDU3IDUuNTA2IDJ6Ii8+DQo8L3N2Zz4NCg==')
    no-repeat center;
}

/* Collection default theme */

/* .ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
} */

/* Grid default theme */
/* 
.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
} */

/* Table default theme */
/* 
.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ReactVirtualized__Table__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24px;
          flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
} */

/* List default theme */
/* 
.ReactVirtualized__List {
} */
.wrapper::after {
  content: '+';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 40px;
  color: #fff; }

.icon {
  display: inline-block;
  font-size: 24px;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  color: #fff; }
  .icon:nth-of-type(1) {
    -webkit-transform: translate(-100%, -100%);
            transform: translate(-100%, -100%); }
  .icon:nth-of-type(2) {
    -webkit-transform: translate(-100%, 100%);
            transform: translate(-100%, 100%); }
  .icon:nth-of-type(3) {
    -webkit-transform: translate(100%, 100%);
            transform: translate(100%, 100%); }
  .icon:nth-of-type(4) {
    -webkit-transform: translate(100%, -100%);
            transform: translate(100%, -100%); }







.content-container {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: relative; }

.video-container {
  position: relative;
  width: 420px;
  height: 340px;
  display: -webkit-flex;
  display: flex;
  border: 5px solid black; }

.video-content {
  width: 100%;
  height: 100%; }


.sketchbook {
  position: relative; }

