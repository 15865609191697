@font-face {
  font-family: 'NotoSans';
  src: url('./files/NotoSans-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./files/NotoSans-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./files/NotoSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'NotoSans';
  src: url('./files/NotoSans-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}
