/* Generated by grunt-webfont */



@font-face {
	font-family:"icons";
	src:url("Wisenet-Icon.woff2?cf2cc1da630b82c574c195a8c265b392") format("woff2"),
		url("Wisenet-Icon.woff?cf2cc1da630b82c574c195a8c265b392") format("woff"),
		url("Wisenet-Icon.ttf?cf2cc1da630b82c574c195a8c265b392") format("truetype"),
		url("Wisenet-Icon.svg?cf2cc1da630b82c574c195a8c265b392#icons") format("svg");
	font-weight:normal;
	font-style:normal;
}

.wni {
	
		font-family:"icons";
	
	display:inline-block;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


/* Icons */


.wni-add:before {
	content:"\f101";
}


.wni-alarm-device:before {
	content:"\f102";
}


.wni-alarm-hdd:before {
	content:"\f103";
}


.wni-alarm-network:before {
	content:"\f104";
}


.wni-alarm-notice:before {
	content:"\f105";
}


.wni-alarm-off:before {
	content:"\f106";
}


.wni-alarm-on:before {
	content:"\f107";
}


.wni-alarm:before {
	content:"\f108";
}


.wni-areazoom:before {
	content:"\f109";
}


.wni-arrow-down:before {
	content:"\f10a";
}


.wni-arrow-left:before {
	content:"\f10b";
}


.wni-arrow-right:before {
	content:"\f10c";
}


.wni-arrow-up:before {
	content:"\f10d";
}


.wni-aspact-ratio:before {
	content:"\f10e";
}


.wni-back-jump:before {
	content:"\f10f";
}


.wni-back-slow:before {
	content:"\f110";
}


.wni-backmode:before {
	content:"\f111";
}


.wni-backward-slow:before {
	content:"\f112";
}


.wni-camera-analog-authority:before {
	content:"\f113";
}


.wni-camera-analog-covert:before {
	content:"\f114";
}


.wni-camera-analog-videoloss:before {
	content:"\f115";
}


.wni-camera-analog:before {
	content:"\f116";
}


.wni-camera-box-authority:before {
	content:"\f117";
}


.wni-camera-box-covert:before {
	content:"\f118";
}


.wni-camera-box-rec:before {
	content:"\f119";
}


.wni-camera-box-videoloss:before {
	content:"\f11a";
}


.wni-camera-box:before {
	content:"\f11b";
}


.wni-camera-ptz-authority:before {
	content:"\f11c";
}


.wni-camera-ptz-covert:before {
	content:"\f11d";
}


.wni-camera-ptz-rec:before {
	content:"\f11e";
}


.wni-camera-ptz-videoloss:before {
	content:"\f11f";
}


.wni-camera-ptz:before {
	content:"\f120";
}


.wni-camerainfo-showhide:before {
	content:"\f121";
}


.wni-capture:before {
	content:"\f122";
}


.wni-caution:before {
	content:"\f123";
}


.wni-close:before {
	content:"\f124";
}


.wni-date:before {
	content:"\f125";
}


.wni-delete:before {
	content:"\f126";
}


.wni-dzoom:before {
	content:"\f127";
}


.wni-edit:before {
	content:"\f128";
}


.wni-event-defoucs:before {
	content:"\f129";
}


.wni-event-face:before {
	content:"\f12a";
}


.wni-event-iframe-video:before {
	content:"\f12b";
}


.wni-event-iva:before {
	content:"\f12c";
}


.wni-event-motion:before {
	content:"\f12d";
}


.wni-event-sdcard-full:before {
	content:"\f12e";
}


.wni-event-sdcard-over:before {
	content:"\f12f";
}


.wni-event-sensor:before {
	content:"\f130";
}


.wni-event-tampering:before {
	content:"\f131";
}


.wni-event-textevent:before {
	content:"\f132";
}


.wni-export:before {
	content:"\f133";
}


.wni-filter:before {
	content:"\f134";
}


.wni-fisheyemode:before {
	content:"\f135";
}


.wni-forward-slow:before {
	content:"\f136";
}


.wni-front-jump:before {
	content:"\f137";
}


.wni-front-slow:before {
	content:"\f138";
}


.wni-full-screen:before {
	content:"\f139";
}


.wni-help:before {
	content:"\f13a";
}


.wni-instant-playback:before {
	content:"\f13b";
}


.wni-list-view:before {
	content:"\f13c";
}


.wni-logout:before {
	content:"\f13d";
}


.wni-mic:before {
	content:"\f13e";
}


.wni-monitoring:before {
	content:"\f13f";
}


.wni-osd-showhide:before {
	content:"\f140";
}


.wni-page-sequence:before {
	content:"\f141";
}


.wni-pattern-1:before {
	content:"\f142";
}


.wni-pattern-4:before {
	content:"\f143";
}


.wni-pattern-9:before {
	content:"\f144";
}


.wni-pause:before {
	content:"\f145";
}


.wni-pc-recording:before {
	content:"\f146";
}


.wni-play:before {
	content:"\f147";
}


.wni-print:before {
	content:"\f148";
}


.wni-ptz-control:before {
	content:"\f149";
}


.wni-ptz-far:before {
	content:"\f14a";
}


.wni-ptz-group:before {
	content:"\f14b";
}


.wni-ptz-mode-arrow:before {
	content:"\f14c";
}


.wni-ptz-near:before {
	content:"\f14d";
}


.wni-ptz-preset:before {
	content:"\f14e";
}


.wni-ptz-swing:before {
	content:"\f14f";
}


.wni-ptz-tour:before {
	content:"\f150";
}


.wni-ptz-trace:before {
	content:"\f151";
}


.wni-ptz-zoomin:before {
	content:"\f152";
}


.wni-ptz-zoomout:before {
	content:"\f153";
}


.wni-rec-status:before {
	content:"\f154";
}


.wni-recording:before {
	content:"\f155";
}


.wni-refresh:before {
	content:"\f156";
}


.wni-return-1-x:before {
	content:"\f157";
}


.wni-rotate:before {
	content:"\f158";
}


.wni-save:before {
	content:"\f159";
}


.wni-search:before {
	content:"\f15a";
}


.wni-setup:before {
	content:"\f15b";
}


.wni-side-showhidden:before {
	content:"\f15c";
}


.wni-sorting:before {
	content:"\f15d";
}


.wni-sound:before {
	content:"\f15e";
}


.wni-status:before {
	content:"\f15f";
}


.wni-textsearch:before {
	content:"\f160";
}


.wni-thumbnail-view:before {
	content:"\f161";
}


.wni-tile-logo:before {
	content:"\f162";
}


.wni-timelinepicker:before {
	content:"\f163";
}


.wni-timesearch:before {
	content:"\f164";
}


.wni-tracking-auto:before {
	content:"\f165";
}


.wni-tracking-manual:before {
	content:"\f166";
}


.wni-user:before {
	content:"\f167";
}


.wni-video-authority:before {
	content:"\f168";
}


.wni-video-disconnect:before {
	content:"\f169";
}


.wni-video-maxuser:before {
	content:"\f16a";
}


.wni-video-power:before {
	content:"\f16b";
}


.wni-video-videoloss:before {
	content:"\f16c";
}
