// @import '../wisenet-ui/styles/utils';

.live-playback-template {
  width: 100%;
  height: calc(100vh);
  display: flex;

  .sidebar {
    flex-basis: 300px;
    border-right: 1px solid #ccc;

    li {
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 1px solid #ccc;
    }
  }

  .content {
    flex-grow: 1;
  }
}
