/**
 * Extra class applied to VirtualScroll through className prop
 */
 .rst__virtualScrollOverride {
  overflow: auto !important;
  will-change: auto !important;
}
.rst__virtualScrollOverride * {
  box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__node.rst__rtl {
  text-align: right;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  /* height: 100%; */
  padding: 0 5px 0 10px;
  /* min-width: 170px; */
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

/* .rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
} */

/* .rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
} */

/* .rst__rowLabel {
  flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
} */

/* .rst__rowToolbar {
  flex: 0 1 auto;
  display: flex;
} */

/* .rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
} */

/* .rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
} */

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__rowTitle {
  font-size: 14px;
  /* font-weight: bold; */
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  /* box-shadow: 0 0 0 1px #000; */
  width: 12px;
  height: 12px;
  padding: 0;
  top: 40%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  /* box-shadow: 0 0 0 1px #000, 0 0 1px 3px; */
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 16px;
  height: 14px;
  width: 14px;
}

.rst__collapseButton {
  background:
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4NCiAgICA8cGF0aCBmaWxsPSIjNEM0QzRDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4xMTUgMTQuODkzYS40Mi40MiAwIDAgMS0uMjk1LS4xMjJMMi4zOCA3LjMyOWwuNTg5LS41ODkgNy4xNDYgNy4xNDggNy4xNDktNy4xNDguNTg5LjU5LTcuNDQzIDcuNDQyYS40Mi40MiAwIDAgMS0uMjk1LjEyMiIvPg0KPC9zdmc+DQo=')
    no-repeat center;
}

.rst__expandButton {
  background:
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4NCiAgICA8cGF0aCBmaWxsPSIjNEM0QzRDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjUwNiAybDYuMzkgNS43NzJjLjEzOS4xMjYuMTM5LjMzIDAgLjQ1N0w1LjUwNSAxNCA1IDEzLjU0MyAxMS4xMzYgOCA1IDIuNDU3IDUuNTA2IDJ6Ii8+DQo8L3N2Zz4NCg==')
    no-repeat center;
}

/* Collection default theme */

/* .ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
} */

/* Grid default theme */
/* 
.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
} */

/* Table default theme */
/* 
.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ReactVirtualized__Table__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 24px;
          flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
} */

/* List default theme */
/* 
.ReactVirtualized__List {
} */